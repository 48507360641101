import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import PhotoTile from '../../Common/fPhoto';


const Index = (props) => {

    const [Chips] = useState([]);
    const [TaxInfo, setTaxInfo] = useState({});
    const [loading,setLoading] = useState(true);
    const [Invoice, setInvoice] = useState({});
    const [Vendor, setVendor] = useState({});


    useEffect(()=>{
        setInvoice(props.details)
        setTaxInfo(props.details.taxInfo)
        setVendor(props.details.vendor);
        setLoading(false);
    },[props.details])


    const renderTable = (item, index) => {
        return(
             <tr key={`row-${index}`}>
                 <td>{item.name}</td>
                 <td>{TaxInfo.currency} {item.base}</td>
                 <td>{TaxInfo.currency} {item.tax}</td>
                 <td>{TaxInfo.currency} {item.amount}</td>
             </tr>
        )
     }

    if(loading) {
        return<></>
    }
    return(
        <div>
                <div className="row mt-5">
                        <div className="col-md-12">
                            <div>
                                {Chips.map((chip, index)=>{
                                    return (
                                        <div key={index} className='status-badge' style={{background: '#f2f2f2',marginLeft: '5px'}}>{chip.key}: {chip.value}</div>
                                    )
                                })}
                            </div>
                            <div className='p-4 mt-4' style={{border: '1px solid #ddd', borderRadius: '10px'}}>
                                <div className='row'>
                                    <div className='col-md-6' style={{marginBottom:10}}>
                                        <b>Invoice ID : {Invoice.invoiceId}</b>
                                        <br/>
                                        Date: {Moment(Invoice.date).format('DD-MM-YYYY')} <br/>
                                        Service: {Invoice.service} <br/>
                                        Category: {Invoice.category} <br/>
                                    </div>
                                    <div className='col-md-6'>
                                        <b>Vendor:</b><br/>
                                        {Vendor.name}<br/>
                                        {Vendor.email}<br/>
                                        {Vendor.phone}<br/>
                                    </div>
                                </div>

                               

                                <div className="table-responsive">
                                    <table className="table table-bordered">
                                        <thead style={{lineHeight: '32px'}}>
                                        <tr style={{textTransform: 'uppercase'}}>
                                            <th>Description</th>
                                            <th>Base Amount</th>
                                            <th>Tax</th>
                                            <th>Amount</th>
                                        </tr>   
                                        </thead>
                                        <tbody className="table-striped">
                                            {Invoice.lineItems.map(renderTable)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3" style={{justifyContent: 'end'}}>
                        <div className="col-md-12" style={{display: 'inline-flex', justifyContent: 'end'}}>
                            <div className='d-flex mt-2' style={{border: '1px solid #ddd', borderRadius: '10px'}}>
                                <div className='me-4 text-muted'>
                                    <div className='p-2 ps-3' style={{borderBottom: '1px solid #ddd'}}>CGST:</div>
                                    <div className='p-2 ps-3' style={{borderBottom: '1px solid #ddd'}}>SGST:</div>
                                    <div className='p-2 ps-3' style={{borderBottom: '1px solid #ddd'}}>IGST:</div>
                                    <div className='p-2 ps-3 fw-bold' style={{borderBottom: '1px solid #ddd', whiteSpace: 'nowrap'}}>Base Amount:</div>
                                    <div className='p-2 ps-3' style={{borderBottom: '1px solid #ddd', whiteSpace: 'nowrap'}}>Tax Amount:</div>
                                    <div className='p-2 ps-3' style={{borderBottom: '1px solid #ddd', whiteSpace: 'nowrap'}}>Total Amount:</div>
                                    <div className='p-2 ps-3' style={{borderBottom: '1px solid #ddd', whiteSpace: 'nowrap'}}>TDS Amount:</div>
                                    <div className='p-2 ps-3 fw-bold'>Payable Amount:</div>
                                </div>
                                <div className='fw-bold'>
                                    <div className='p-2 ps-4 pe-4' style={{borderBottom: '1px solid #ddd', marginLeft: '-30px'}}>{TaxInfo.cgst}</div>
                                    <div className='p-2 ps-4 pe-4' style={{borderBottom: '1px solid #ddd', marginLeft: '-30px'}}>{TaxInfo.sgst}</div>
                                    <div className='p-2 ps-4 pe-4' style={{borderBottom: '1px solid #ddd', marginLeft: '-30px'}}>{TaxInfo.igst}</div>
                                    <div className='p-2 ps-4 pe-4' style={{borderBottom: '1px solid #ddd', marginLeft: '-30px'}}>{TaxInfo.baseAmount}</div>
                                    <div className='p-2 ps-4 pe-4' style={{borderBottom: '1px solid #ddd', marginLeft: '-30px'}}>{TaxInfo.tax}</div>
                                    <div className='p-2 ps-4 pe-4' style={{borderBottom: '1px solid #ddd', marginLeft: '-30px'}}>{TaxInfo.total}</div>
                                    <div className='p-2 ps-4 pe-4' style={{borderBottom: '1px solid #ddd', marginLeft: '-30px'}}>{TaxInfo.tds}</div>
                                    <div className='p-2 ps-4 pe-4' style={{marginLeft: '-30px', whiteSpace: 'nowrap'}}>{TaxInfo.paidAmount}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3 p-3'>
                        <div className="col-md-12 p-4" style={{border: '1px solid #ddd', borderRadius: '10px'}}>
                            <span className='fw-bold'>Attachments</span>
                            <div className='mt-4 d-flex' style={{flexWrap: 'wrap'}}>
                                {
                                    Invoice.files.length > 0 ?
                                    Invoice.files.map((item, index) => {
                                            return (
                                                <PhotoTile key={`Photo-${index}`} link={item}/>
                                            )
                                        }) : <></>
                                }
                            </div>
                        </div>
                    </div>
                  
        </div>
    )
}

export default Index;