import React from 'react';
import PropTypes from 'prop-types'
import {SingleDatePicker} from "react-dates";
import {v4} from "uuid";
import './range.css'

class FloatDate extends React.Component {


    state = {
        dateModal: undefined,
        date: undefined
    }

    getValue = () => {
        return this.state.date;
    }

    setValue = (value) => {
        this.setState({
            ...this.state,
            date: value
        })
    }

    render() {
        return (
            <div style={{ ...this.props.style }}>
                <div>{this.props.title}</div>
                <div style={{
                    ...this.props.containerStyle,
                    marginBottom: 20
                }}>
                    <div className='form-builder'>
                        <SingleDatePicker
                            date={this.state.date}
                            isOutsideRange={() => false}
                            displayFormat="DD MMM YYYY"
                            numberOfMonths={1}
                            onDateChange={date => this.setState({ date })}
                            focused={this.state.focused}
                            withPortal={true}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            placeholder={this.props.placeholder ? this.props.placeholder : 'Select Date'}
                            id={v4()}
                        />
                    </div>
                </div>
            </div>
        )
    }
}


FloatDate.propTypes = {
    title: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired
}


export { FloatDate };

export default FloatDate;
