import React from "react";

export const Landing = () => {
	return (
		<iframe
			src={`https://dicewebsite.netlify.app${window.location.pathname}`}
			title="Dice Website"
			style={{
				width: "100vw",
				height: "100vh",
				border: "none",
				outline: "none",
			}}
		/>
	);
};
