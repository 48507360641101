import { api } from '../Api/config';
import axios from 'axios';
import qs from 'qs'
axios.defaults.baseURL = api;
axios.defaults.timeout = 62500;
axios.interceptors.request.use(function (config) {
    config.headers['Access-Control-Allow-Origin'] = "*";
    config.headers['Content-Type'] = "application/json";
    config.headers['X-CLIENT-ID'] = '586E3272357538782F4125442A472D4B';
    config.headers['X-CLIENT-SECRET'] = '28482B4D6251655468576D5A7134743x';
    return config;
}, function (err) {
    return Promise.reject(err);
});


const post = (url, data, cb) => {
    axios.post(url, data).then(res => {
        cb(null, res.data)
    }).catch(err => cb(err, null))
}

const patch = (url, data, cb) => {
    axios.patch(url, data).then(res => {
        cb(null, res.data)
    }).catch(err => cb(err, null))
}
const put = (url, data, cb) => {
    axios.put(url, data).then(res => {
        cb(null, res.data)
    }).catch(err => cb(err, null))
}

const get = (url, cb, data = {}) => {
    axios.get(url, {
        params: data,
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'repeat' })
        }
    }).then(res => cb(null, res.data)).catch(cb);
}

export {
    post, get, axios, patch, put
}
