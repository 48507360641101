import React from 'react'
import Props from 'prop-types'
import FloatInput from "../Views/FloatInput";
import {FileUpload} from "../Views/FloatFile";
import FloatDate from "../Views/FloatDate";
import FloatSelect from '../Views/FloatSelect'
import {Master} from '../Master'

class CustomForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			master:{}
		}
	}

	componentDidMount() {
		if (this.props.handleRef) {
			this.props.handleRef(this);
		}
	}

	mapItem = (item) => {
		if (item.type === 'EMAIL' || item.type === 'NUMBER' || item.type === 'TEXT') {
			return (
				<div key={item.id} style={{ marginBottom: 18 }}>
					<FloatInput title={item.name} type={item.type.toLowerCase()} placeholder={item.placeholder}
						handleRef={ref => this[`input_${item.id}`] = ref} />
				</div>)
		} else if (item.type === "DATE") {
			return (
				<div key={item.id} style={{ marginBottom: 18 }}>
					<FloatDate title={item.name} placeholder={item.placeholder}
						ref={ref => this[`input_${item.id}`] = ref} /></div>
			)
		} else if (item.type === "SELECT") {
			return (
				<div key={item.id} style={{ marginBottom: 18 }}>
					<FloatSelect title={item.name} search={false} items={item.values} ref={ref => {
						this[`input_${item.id}`] = ref
					}} />
				</div>
			)
		} else if (item.type === "FILE") {
			return (
				<div key={item.id} style={{ marginBottom: 18 }}>
					<FileUpload title={item.name} ref={ref => {
						this[`input_${item.id}`] = ref
					}} />
				</div>
			)
		} else if(item.type === 'MASTER') {
			return(
				<div key={item.id} style={{marginTop:'10px'}}>
					<Master
						label={`${item.name}${item.required?'*':''}`}
						placeholder={`Search the ${item.name}`}
						fieldId={item.fieldId?item.fieldId:item.name}
						master={this.state.master}
						handleRef={ref => {this[`input_${item.id}`] = ref}}
						details={item}
						token={this.props.token}
						onUpdate={(key,value)=>{
							let obj = {...this.state.master};
							obj[key] = value;
							this.setState({
								obj
							})
						}}

					/>
				</div>
			)
		}
	}

	getForm = cb => {
		let data = {}
		for (let item of this.props.forms) {
			if (item.type === 'EMAIL' || item.type === 'NUMBER' || item.type === 'TEXT') {
				let value = this[`input_${item.id}`].getValue();
				if (!value && item.required) {
					alert(`${item.name} is required`)
					return;
				}
				data[item.id] = value;
			} else if (item.type === 'DATE') {
				let value = this[`input_${item.id}`].getValue();
				if (!value && item.required) {
					alert(`${item.name} is required`)
					return;
				}
				data[item.id] = value.valueOf();
			} else if (item.type === 'SELECT') {
				let value = this[`input_${item.id}`].getValue();
				if (!value && item.required) {
					alert(`Select a value for ${item.name}`)
					return;
				}
				data[item.id] = value.key;
			} else if (item.type === 'FILE') {
				let value = this[`input_${item.id}`].getValue();
				if (!value && item.required) {
					alert(`${item.name} attachment is required`)
					return;
				}
				if(value) {
					data[item.id] = value;
				}
			} else if(item.type === 'MASTER') {
				let value = this[`input_${item.id}`].getValue();
				if(!value && item.required) {
					alert(`${item.name} is required`)
					return
				}
				data[item.id] = value
		   }
		}
		cb(data)
	}

	render() {
		return (
			<div>
				<p style={{ fontSize: 19, fontWeight: 600, opacity: .9, marginTop: 18 }}>
					{this.props.title}
				</p>
				<p style={{ fontSize: 13, fontWeight: 400, opacity: .5, marginBottom: 18 }}>
					{this.props.des}
				</p>
				{this.props.forms.map(this.mapItem)}
			</div>
		)
	}

}

CustomForm.propTypes = {
	title: Props.string.isRequired,
	des: Props.string.isRequired,
	forms: Props.array.isRequired
}

CustomForm.defaultProps = {
	forms: [],
	title: "",
	des: ""
}

export {
	CustomForm
}
