import React, {useEffect, useState} from 'react';
import connected from './connect.png'
import error from './error.png'
import {useLocation} from 'react-router-dom'

function Index(props) {

    const [getFLAG, setFLAG] = useState(true);
    const LOCATION = useLocation().search;

    useEffect(() => {
        let flag = new URLSearchParams(LOCATION).get('success');
        setFLAG(flag==='true'?true:false)
    }, [LOCATION]);

    return (<>
    <div style={{width: '100%', height: 'calc(100vh - 70px)', padding: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
       <div className="container cards" style={{maxWidth: 400}}>
            <div className="row">
                {
                    getFLAG
                    ?<div className="col-md-12" style={{textAlign: 'center'}}><img className="img-fluid" src={connected} alt="" style={{maxWidth: 100}} />
                        <h1 className="display-3 fw-bold mt-5">App<br/>Connected</h1>
                        <p className="mt-4 text-muted fw-light">Your app is successfully connected, <br/>You can close this window and continue using dice.</p>
                    </div>
                    :<div className="col-md-12" style={{textAlign: 'center'}}><img className="img-fluid" src={error} alt="" style={{maxWidth: 100}} />
                        <h1 className="display-3 fw-bold mt-5">Connection <br/>Problem</h1>
                        <p className="mt-4 text-muted fw-light">We were not able to connect your app, <br/>You can try connecting again or contact our team.</p>
                    </div>
                }
            </div>
        </div>
        </div>
    </>);
}

export default Index;