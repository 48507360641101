import React, { useEffect, useRef, useState } from "react";
import { post } from "../../../Model/Network/Config/Axios";
import Tick from "../tick.png";
import Decline from "../decline.png";
import Ques from "../question.png";
import { Spinner } from "../../Common/fLoader";
import { CustomForm as Form } from "../../Common/Form";
import moment from "moment";
import TabSwtich from "../../Common/fTabSwitch";
import Track from "../../Common/fTrack";
import InvoiceDetails from "./Invoice";
import { Dialog } from "../../Common/Dialog";

function Index(props) {
	const Token = props.token;
	//Decoding Token for origin
	let Origin = "";
	var base64Url = Token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(
		atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join("")
	);
	let json = JSON.parse(jsonPayload);
	if (json.origin) {
		Origin = json.origin;
	}

	const [getTab, setTab] = useState(1);
	const [GRN, setGRN] = useState({});
	const [Items, setItems] = useState([]);
	const [Invoice, setInvoice] = useState({});
	const [Files, setFiles] = useState([]);

	const [Modal, setModal] = useState(undefined);
	const [Status, setStatus] = useState(undefined);
	const [Processing, setProcessing] = useState(false);

	let Remarks = useRef();
	const FORM = useRef(null);

	let tabs = [
		{
			name: "GRN",
			active: getTab === 1,
			onClick: () => {
				setTab(1);
			},
		},
		{
			name: "Inputs",
			active: getTab === 2,
			onClick: () => {
				setTab(2);
			},
		},
		{
			name: "Invoice",
			active: getTab === 3,
			onClick: () => {
				setTab(3);
			},
		},
		{
			name: "Timeline",
			active: getTab === 4,
			onClick: () => {
				setTab(4);
			},
		},
	];

	useEffect(() => {
		if (props.grn) {
			setGRN(props.grn);
			setStatus("Success");
			if (props.grn.details) {
				let response = JSON.parse(props.grn.details.data);
				setFiles(response.files);
				setItems(response.grns);
				setInvoice(response.invoice);
			}
		} else {
			setStatus("Invalid");
		}
	}, [Token, props]);

	const renderTable = (item, index) => {
		return (
			<tr key={`row-${index}`}>
				<td>
					{item.name}
					<br />
					Address: {item.address}
				</td>
				<td>{item.qtty}</td>
			</tr>
		);
	};

	const renderAttachments = (file, index) => {
		return (
			<div key={`file-${index}`} onClick={() => window.open(file, "_blank")} style={{ cursor: "pointer", display: "flex", alignItems: "center", fontSize: "12px", marginTop: " 10px", color: "#0d6efd" }}>
				View Attachment
				<svg style={{ marginLeft: "10px" }} className="bi bi-box-arrow-up-right" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
					<path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"></path>
					<path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"></path>
				</svg>
			</div>
		);
	};

	const rednerValues = (item, index) => {
		return (
			<div key={`values-${index}`} className="p-3 mt-2 bg-light ms-2" style={{ borderRadius: "10px" }}>
				<div className="text-muted">{item.key}</div>
				<div className="fw-bold">{item.value}</div>
			</div>
		);
	};

	const renderViews = (item, index) => {
		return (
			<div key={`views-${index}`} className="mt-4 row">
				<span className="fw-bold h5">{item.title}</span>
				<br />
				<span>Date: {moment(item.date).format("hh:mm a DD MMM YY")}</span>
				<span>{item.description}</span>
				<div className="col-md-12" style={{ display: "flex", flexWrap: "wrap", marginLeft: "-10px", marginTop: "15px" }}>
					{item.values.map(rednerValues)}
				</div>
			</div>
		);
	};

	const approve = () => {
		FORM.current.getForm((cb) => {
			let data = {
				token: Token,
				forms: cb,
			};

			setProcessing(true);
			post(`${Origin}task.post`, data, (e, r) => {
				if (r) {
					setModal(undefined);
					setStatus("Approved");
					setProcessing(false);
				} else {
					setProcessing(false);
					alert("Problem in Approving");
				}
			});
		});
	};

	const decline = () => {
		let remarks = Remarks.current.value;

		if (!remarks) {
			alert("Remarks is required");
			return;
		}

		let data = {
			token: Token,
			remarks: remarks,
		};

		setProcessing(true);
		post(`${Origin}task.cancel`, data, (e, r) => {
			if (r) {
				setModal(undefined);
				setStatus("Declined");
				setProcessing(false);
			} else {
				setProcessing(false);
				alert("Problem in Declining");
			}
		});
	};

	return (
		<>
			<div className="mt-5 mb-5">
				{Status === "Success" ? (
					<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px" }}>
						<h6 className="fw-bold text-muted">
							{GRN.title} / {GRN.description}
						</h6>
						<h1 className="display-4 fw-bold">GRN Approval</h1>
						<p className="mb-2">You have pending approval for this purchase order. Please verify the details and approve</p>
						<p className="pt-1 pb-1 mb-5 text-white rounded bg-success ps-2 pe-2 d-inline-flex" style={{ fontSize: "10px" }}>
							{GRN.stage ? GRN.stage.toUpperCase() : ""} STAGE
						</p>

						<TabSwtich primaryColor={props.primaryColor} tabs={tabs} />
						{getTab === 1 ? (
							<>
								{GRN.views ? GRN.views.map(renderViews) : <></>}
								<div className="mt-4 row" style={{ marginLeft: "-1rem" }}>
									<h1 className="h6 fw-bold ms-2">Invoice Details</h1>
									<div className="col-md-12" style={{ display: "flex", flexWrap: "wrap" }}>
										<div className="p-3 mt-2 bg-light ms-2" style={{ borderRadius: "10px" }}>
											<div className="text-muted">Invoice ID</div>
											<div className="fw-bold">{Invoice.invoiceId}</div>
										</div>
										<div className="p-3 mt-2 bg-light ms-2" style={{ borderRadius: "10px" }}>
											<div className="text-muted">Date</div>
											<div className="fw-bold">{moment(Invoice.date).format("DD-MM-YYYY")}</div>
										</div>

										<div className="p-3 mt-2 bg-light ms-2" style={{ borderRadius: "10px" }}>
											<div className="text-muted">Vendor</div>
											<div className="fw-bold">{Invoice.vendor ? Invoice.vendor.name : ""}</div>
										</div>
									</div>
								</div>
								<div className="mt-5 row">
									<div className="col-md-12">
										<h1 className="h6 fw-bold">GRN Items</h1>
										<div className="mt-3 table-responsive">
											<table className="table table-bordered">
												<thead style={{ lineHeight: "32px" }}>
													<tr style={{ textTransform: "uppercase" }}>
														<th>Description</th>
														<th>Qty</th>
													</tr>
												</thead>
												<tbody className="table-striped">{Items.length ? Items.map(renderTable) : <></>}</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="mt-3 row" style={{ maxWidth: 500 }}>
									<div className="col-md-12">
										<span className="fw-bold">TOTAL</span>
										<div className="mt-2 d-flex">
											<div className="me-4 text-muted">
												<div>Total Amount:</div>
											</div>
											<div className="fw-bold">
												<div>
													{Invoice.currency} {Invoice.finalAmount}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="pt-2 mt-4 row">
									<div className="pt-4 col-md-12">
										<span className="fw-bold">Attachments</span>
										{Files.length ? Files.map(renderAttachments) : <div>No Attachments found</div>}
									</div>
								</div>
								<div className="p-3 mt-4 row">
									<div className="p-4 col-md-12">
										{props.form.length ? <span className="fw-bold">Custom Form</span> : <></>}
										<Form token={Token} title="" des="" forms={props.form} handleRef={(ref) => (FORM.current = ref)} />
									</div>
								</div>
								<div className="mt-4 row" style={{ maxWidth: "500px" }}>
									<div className="mt-3 col-md-6">
										<button onClick={() => setModal("Approve")} className="btn btn-success" type="button" style={{ width: "100%", height: "50px" }}>
											<svg className="bi bi-check-lg me-2" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
												<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
											</svg>
											Approve
										</button>
									</div>
									<div className="mt-3 col-md-6">
										<button onClick={() => setModal("Decline")} className="btn btn-secondary" type="button" style={{ width: "100%", height: "50px" }}>
											Decline
										</button>
									</div>
								</div>
							</>
						) : getTab === 2 ? (
							<>
								<div className="p-3 mt-3 row">
									<div className="p-4 col-md-12">
										<span className="fw-bold">Inputs</span>
										<div className="mt-4">
											{props.inputs.length ? (
												props.inputs.map((item) => {
													return (
														<div style={{ display: "flex", marginTop: "10px", justifyContent: "space-between", maxWidth: "400px", border: "1px solid #ddd", padding: "10px", borderRadius: "7px", background: "#f2f2f2" }}>
															<div style={{ fontWeight: "bold" }}>{item.key}</div>
															<div>{item.value}</div>
														</div>
													);
												})
											) : (
												<div>No Inputs Received</div>
											)}
										</div>
									</div>
								</div>
							</>
						) : getTab === 3 ? (
							<>
								<InvoiceDetails details={Invoice} />
							</>
						) : getTab === 4 ? (
							<>
								<div className="p-4 mt-5" style={{ border: "1px solid #ddd", borderRadius: "10px" }}>
									{props.timelines ? (
										<Track
											stages={[
												...props.timelines.map((log) => {
													let DESC = (
														<div>
															<b>{log.name}</b>
															<br />
															<div style={{ marginTop: "5px" }}>Type: {log.type}</div>
															{log.status ? <div style={{ marginTop: "5px" }}>Status: {log.status}</div> : null}
															<div style={{ marginTop: "5px" }}>
																Sent on: {moment(log.time).format("hh:mm a DD MMM YY")}
																<br />
																{log.status === "PENDING" ? "-" : log.status === "DECLINED" ? `Declined on: ${moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : log.actionTime ? `Approved on: ${moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : ""}
															</div>
														</div>
													);

													return {
														title: log.email,
														desc: DESC,
														status: log.type === "start" ? true : log.status ? log.status === "COMPLETED" : false,
													};
												}),
											]}
										/>
									) : (
										<></>
									)}
								</div>
							</>
						) : (
							<></>
						)}
					</div>
				) : Status === "Approved" ? (
					<>
						<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px", textAlign: "center" }}>
							<img className="m-auto mb-4 img-fluid" src={Tick} style={{ maxWidth: "80px" }} alt="" />
							<h1 className="display-6 fw-bold">Approved</h1>
							<p>GRN Approved</p>
						</div>
					</>
				) : Status === "Declined" ? (
					<>
						<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px", textAlign: "center" }}>
							<img className="m-auto mb-4 img-fluid" src={Decline} style={{ maxWidth: "80px" }} alt="" />
							<h1 className="display-6 fw-bold">Declined</h1>
							<p>GRN Declined</p>
						</div>
					</>
				) : Status === "Invalid" ? (
					<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px", textAlign: "center" }}>
						<img className="m-auto mb-4 img-fluid" src={Ques} style={{ maxWidth: "80px" }} alt="" />
						<h1 className="display-4 fw-bold">Invalid Request</h1>
						<p>This Po request is expired and not available for review.</p>
					</div>
				) : (
					<Spinner style={{ marginTop: "150px" }} />
				)}
			</div>
			<Dialog open={Modal} onClose={() => setModal(undefined)} style={{ maxWidth: "500px" }}>
				<div className="row">
					<div className="p-2 col-md-12 position-relative">
						<div onClick={() => setModal(undefined)} className="position-absolute" style={{ fontSize: "2rem", zIndex: "111", right: "20px", top: "10px", cursor: "pointer" }}>
							<svg className="bi bi-x-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
								<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
								<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
							</svg>
						</div>
						<div>
							{!Processing ? (
								<div className="p-5 m-auto text-center" data-aos="fade-up">
									{Modal === "Approve" ? (
										<>
											<h1 className="display-6 fw-bold">Approve</h1>
											<p>Do you want to approve this GRN?</p>
										</>
									) : Modal === "Decline" ? (
										<>
											<h1 className="display-6 fw-bold">Decline</h1>
											<p>Are you sure you want to decline this GRN?</p>
										</>
									) : (
										<></>
									)}

									{Modal === "Decline" ? <input ref={Remarks} style={{ height: "50px" }} className="mt-2 mb-3 form-control" type="text" placeholder="Enter Remarks" /> : <></>}

									{Modal === "Approve" ? (
										<button onClick={approve} className="mt-3 btn btn-success" type="button" style={{ height: "50px" }}>
											<svg className="bi bi-check-lg me-2" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
												<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
											</svg>
											Approve
										</button>
									) : Modal === "Decline" ? (
										<button onClick={decline} className="mt-3 btn btn-danger" type="button" style={{ height: "50px" }}>
											Decline
										</button>
									) : (
										<></>
									)}
								</div>
							) : (
								<div className="p-5 m-auto text-center" data-aos="fade-up">
									<Spinner />
								</div>
							)}
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
}

export default Index;
