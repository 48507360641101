import React, {useEffect, useState} from 'react';
import {post} from '../../Model/Network/Config/Axios'
import Tick from './tick.png'
import Ques from './question.png'
import Eror from './error.png'
import { Spinner } from '../Common/fLoader';
import {useLocation} from 'react-router-dom'

function Index(props) {

    let LOCATION = useLocation()
    const PARAMS = new URLSearchParams(LOCATION.search);
    const Token = PARAMS.get('token');
    
    //Decoding Token for origin
    let Origin = 'https://heimdall.eka.io/'
    var base64Url = Token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    let json = JSON.parse(jsonPayload);
    if(json.origin){
        Origin = json.origin
    }

    const [Status, setStatus] = useState(undefined);
    
    useEffect(() => {
        fetch(Token)
    }, [Token]);

    const fetch = (token) => {
        if(!token){
            setStatus('Invalid')
            return
        }else{
            setStatus('Valid')
            approveAction(token)
        }
    }

    const approveAction = (token) => {
        post(`${Origin}employee/others/action`, { token, confirm: 'yes' }, (e, r) => {
            if (e) {
                setStatus('Error')
            } else if(r) {
                setStatus('Success')
            }
        })
    }

return (<>
    <div className='mt-5 mb-5'>
       {
            Status==='Success'?
            <>
            <div className="container cards" style={{fontSize: '14px', borderRadius: '10px', textAlign: 'center'}}>
                <img className="img-fluid mb-4 m-auto" src={Tick} style={{maxWidth: '80px'}} alt=""/>
                <h1 className="display-6 fw-bold">
                    Success
                </h1>
                <p>
                    Action completed successfully
                </p>
            </div>
            </>
            :Status==='Error'?
            <>
            <div className="container cards" style={{fontSize: '14px', borderRadius: '10px', textAlign: 'center'}}>
                <img className="img-fluid mb-4 m-auto" src={Eror} style={{maxWidth: '80px'}} alt=""/>
                <h1 className="display-6 fw-bold">
                    Oops !
                </h1>
                <p>Error occured, your request was not completed</p>
                <button onClick={()=>approveAction(Token)} className="btn btn-success mt-2" type="button" style={{height: '50px'}}>Retry</button>
            </div>
            </>
            :Status==='Invalid'?
            <div className="container cards" style={{fontSize: '14px', borderRadius: '10px', textAlign: 'center'}}>
                <img className="img-fluid mb-4 m-auto" src={Ques} style={{maxWidth: '80px'}} alt=""/>
                <h1 className="display-4 fw-bold">No Token</h1>
                <p>Your action does not have a valid request token</p>
            </div>
            :<Spinner style={{marginTop: '150px'}}/>
       }
    </div>
    </>);
}

export default Index;