import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Index = () => {
	const [link] = useState(undefined);
	const { code } = useParams();

	useEffect(() => {
		fetch(`https://provider.dice.tech/events/redirect/${code}`)
			.then((res) => {
				return res.json().then((res) => {
					console.log(res);
					window.open(res.url, "_self");
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}, [code]);
	return (
		<div>
			<a href={link || "#"}>Redirecting...</a>
		</div>
	);
};

export default Index;
