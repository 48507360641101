import React from 'react';
import Props from 'prop-types';
import search from './loupe.png';


class FloatSelect extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            value: this.props.value ? this.props.value.key : '',
            select: undefined
        }
    }


    componentDidMount() {
        if (this.props.handleRef) {
            this.props.handleRef(this);
        }
        window.addEventListener('click', this.onClickOutside);
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value && this.props.value) {
            this.setState({
                ...this.state,
                value: this.props.value.key
            })

        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutside);
    }


    onClickOutside = (e) => {
        if (this.state.isOpen && !this.select.contains(e.target)) {
            this.setState({
                isOpen: false
            })
        }
    }


    setValue = () => {
        this.setState({
            select: undefined,
            value: ''
        })
    }

    getValue = () => {
        return this.state.select;
    }

    getInput = () => {
        return this.search.value;
    }

    setAutoFill = (selected) => {
        this.setState({
            value: selected.value,
            key: selected.key,
            select: selected
        })
    }

    onChange = () => {
        let value = this.search.value;
        if (value && value.length > 1) {
            value = value.toLowerCase();
            let items = [];
            this.props.items.forEach(v => {
                if (v.search && v.search.includes(value)) {
                    items.push(v)
                }
            })
            this.setState({
                ...this.state,
                items
            })
        } else {
            this.setState({
                ...this.state,
                items: undefined
            })
        }
    }

    mapItem = (item, index) => {
        let active = item.key === this.state.value;
        return (
            <div key={index}
                onClick={() => {

                    this.setState({
                        value: item.value,
                        key: item.key,
                        select: item,
                        isOpen: false,
                        items: undefined
                    }, () => {
                        if (this.props.onChange) {
                            this.props.onChange(item)
                        }

                    })
                }}
                style={{
                    minHeight: 45,
                    paddingLeft: 20,
                    flexDirection: 'column',
                    fontSize: 14,
                    padding: 10,
                    cursor: 'pointer',
                    borderBottom: '1px dotted rgba(228, 228, 228, 0.1)',
                    ...active ? {} : {}
                }}>
                    {item.value}
                {
                    item.bottom ? <p style={{ opacity: .7, fontSize: 11 }}>{item.bottom}</p> : undefined
                }
                {
                    item.gst ? <p style={{ opacity: .7, fontSize: 11 }}>{item.gst}</p> : undefined
                }
            </div>
        )
    }


    render() {
        let items = this.state.items ? this.state.items : this.props.items;
        return (
            <div ref={ref => this.select = ref} style={{
                ...this.props.style,
                cursor: 'pointer'
            }}>
                {
                    this.props.titlehide ?


                        <div>{this.props.title}</div>
                        : null}
                <div style={{
                    position: 'relative'
                }}>
                    <div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '40px',
                            background: '#fff',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            padding: '10px',
                            maxWidth: '500px',
                            marginTop: '10px',
                            ...this.state.isOpen ?
                                {
                                    borderColor: '#6C5DD3'
                                } :
                                {}
                        }}
                            onClick={() => {
                                this.setState({
                                    isOpen: !this.state.isOpen
                                })
                            }}
                        >
                            {this.state.value ? this.state.value :
                                <div>{this.props.placeholder ? this.props.placeholder : "Select"}</div>}
                        </div>
                    </div>

                    {
                        this.state.isOpen && (
                            <div style={{
                                position: 'absolute',
                                maxHeight: 350,
                                overflowY: 'auto',
                                width: '100%',
                                maxWidth: '500px',
                                top: 0,
                                borderRadius: 8,
                                zIndex: 100,
                                background: '#fff',
                                border: '1px solid #ddd',

                            }}>
                                {
                                    this.props.search ? <div style={{
                                        margin: 4,
                                        height: 50,
                                        borderRadius: 8,
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor:"rgba(228, 228, 228, 0.3)"
                                    }}>
                                        <img alt="search" src={search} style={{
                                            width: 20,
                                            height: 20,
                                            margin: 5
                                        }} />
                                        <input type="text" onChange={this.props.onSearch ? this.props.onSearch : this.onChange} style={{
                                            background: 'transparent',
                                            color: 'var(--text-color)',
                                            fontSize: 15,
                                            paddingLeft: 12,
                                            fontWeight: 600,
                                            width: '100%',
                                            border: 'none'
                                        }} ref={ref => this.search = ref}
                                            placeholder="Enter search criteria to filter result" />
                                    </div> : undefined
                                }
                                {items.map(this.mapItem)}
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}


FloatSelect.propTypes = {
    items: Props.array
}


FloatSelect.defaultProps = {
    titlehide: true
}
export default FloatSelect;

export { FloatSelect }
