import { useState,useEffect } from "react";
import {get, post} from '../../../Model/Network/Config/Axios'
import './index.css'

const Master = (props) => {

    const [suggestion,setSuggestion] = useState([]);
    const [selected,setSelected] = useState(undefined);
    const [value,setValue] = useState('')
    const [next,setNext] = useState(undefined);


    useEffect(()=>{
        if(props.handleRef) {
            props.handleRef({
                getValue:()=>selected
            })
        }
    })

    useEffect(()=>{
        if(value.length>0) {
            get(`/form/${props.details.id}/master`, (e, r) => {
                if (r) {
                    let data = {};
                    if (r.data.length > 0) {
                        Object.keys(props.master).forEach(val => {
                            data[r.data[0]] = props.master[r.data[0]];
                        })
                        setNext(r.next);

                        post(`/form/${props.details.id}/search`, {
                            q: value,
                            data,
                            token:props.token
                        }, (e, r) => {
                            if (r) {
                                setSuggestion(r.results);
                            }
                        })
                    } else {
                        setNext(r.next);
                        post(`/form/${props.details.id}/search`, {
                            q: value,
                            token:props.token

                        }, (e, r) => {
                            if (r) {
                                setSuggestion(r.results);
                            }
                        })
                    }

                }
            }, {
                q: value,
                token:props.token

            })
        } else {
            setValue('');
            setSuggestion([]);
        }
    },[value, props])

     let onChange=(e)=>{
        setValue(e.target.value);
        setSuggestion([]);
        setSelected(undefined);
    }

    let suggestionList = (item,index) => {
        let items = Object.keys(item).map(i => (i));
        let key = Object.keys(item)[0];
        return(
            <div className="item" key={index} onClick={()=>{
                props.onUpdate(next,item[next]);
                setSelected(item[props.fieldId]?item[props.fieldId]:item[key]);
                setValue('');
                setSuggestion([]);
            }}>
                {items.map((val,i)=>{
                    return(
                        <div style={{
                            display:'flex',
                            alignItem:'center'
                        }} key={i}>
                             <div style={{
                                    fontSize: 13,
                                }}>{val} : </div>
                                <div style={{
                                    fontSize: 13
                                }}> {item[val]}</div>
                        </div>
                    )
                })}
            </div>
        )
    }



    return(
        <div className="searchable" style={{...props.style}}>
            <div style={{marginTop: '15px', marginLeft: '5px',marginBottom:10}}>{props.label}</div>
            <div className='mock' style={{maxWidth:500,position:'relative'}}>
                <input onChange={onChange} className="form-control"  type="text" placeholder={props.placeholder} value={selected?selected:value}/>
                <svg style={{position:'absolute',right:5,top:'35%'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path></svg>
            </div>
            {suggestion.length>0  ? (
              <div className="searchable-list FadeDown-Effect">
              <div>
            {
                suggestion.map(suggestionList) 
            }
            </div>
            </div>
            )
            
            : null}
       </div>
    )
}

export {Master}
