import React from 'react';
import './index.css';

function TabSwtich(props) {

    const TabSize = 100/props.tabs.length;
    const renderTabs = (item, index)=>{
        let cls = '';

        if(item.active){
            cls = 'active'
        }else{
            cls = 'inactive'
        }
        let style = {
            width: `${TabSize}%`
        }
        if(props.primaryColor && item.active) {
            style.backgroundColor=props.primaryColor
        }
        return(
            <div key={`Tab-${index}`} onClick={item.onClick} className={`Tab-${cls}`} style={style}>{item.name}</div>
        )
    }

    return (<>
        <div className='TabSwitch'>
            {props.tabs.map(renderTabs)}
        </div>
    </>);
}

export default TabSwtich;