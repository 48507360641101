import React from 'react';
import axios from 'axios';
import PDF from './pdf.png'

class FileUpload extends React.Component {

	state = {
		image: null,
		value: '',
		name: ''
	};

	uploadBtn = React.createRef();

	componentDidMount() {
		if (this.props.handleRef) {
			this.props.handleRef(this);
		}
	}

	fileName = "";
	fileExt = "";

	_upload = () => {
		let file = this.file_ref.files[0];
		if (file) {
			this.uploadBtn.current.innerHTML = "<div class='mini-loader'></div>";
			const formData = new FormData();
			formData.append('file', file);

			axios({
				method: 'post',
				url: 'https://file.eka.io/file',
				data: formData,
				headers: {
					clientId: '422dc3e2-a960-4941-9a7a-af64732157b5',
					clientSecret: "9cd4ce3e-6ea6-45d7-bf92-06c9c7a69873"
				}
			}).then(res => {
				this.setState({
					...this.state,
					image: res.data.url,
					ext: this.file_ref.files[0].name.substring(this.file_ref.files[0].name.length-4),
					name: this.file_ref.files[0].name
				});
				this.uploadBtn.current.innerHTML = "<svg style={{ verticalAlign: 'middle'}} className='bi bi-plus-circle' xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='currentColor' viewBox='0 0 16 16'><path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'></path><path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'></path></svg>"
				
			})

		} else {
			alert('Select a file')
		}
	};

	getValue = ()=>{
		return this.state.image
	}

	isImage = (file)=>{
		if(file.includes('.jpg') || file.includes('.jpeg') || file.includes('.png')){
			return true
		}else{
			return false
		}
	}

	render() {

		return (
			<>
				<div style={{marginBottom: '10px'}}>
					{this.props.title?this.props.title:''}
				</div>
                <input onChange={this._upload} id='fileInput' ref={ref =>this.file_ref = ref} type="file" style={{display: 'none'}} required={true}/>
                <div style={{display: 'flex', alignItems: 'center'}}>
					<div ref={this.uploadBtn} onClick={()=>this.file_ref.click()} style={{width: '70px', height: '70px', fontSize: "24px", color: '#666', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', cursor: 'pointer', borderRadius: '15px', border: '1px dashed #666', backgroundImage: `url('${this.isImage(this.state.name)?this.state.image:this.state.image?PDF:''}')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
						<svg style={{ verticalAlign: "middle"}} className="bi bi-plus-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
							<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
							<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"></path>
						</svg>
					</div>
					<div style={{paddingLeft: '20px'}}>
						{this.state.name}
					</div>
				</div>
			</>
		)
	}
}


export { FileUpload };
