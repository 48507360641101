import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Landing } from "./Website";
import ScrollToTop from "./scrollToTop";
import Connected from "./Connected";
import Approvals from "./Approvals";
import Vouchers from "./Approvals/Voucher";
import Actions from "./Approvals/Actions";
import EmailActions from "./Approvals/EmailActions";
import Redirect from "./Redirect";

class Index extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<ScrollToTop />
				<Routes>
					<Route path="/email/actions" exact={true} element={<EmailActions />} />
					<Route path="/r/:code" element={<Redirect />} />
					<Route path="/vouchers" exact={true} element={<Vouchers />} />
					<Route path="/action" exact={true} element={<Actions />} />
					<Route path="/actions" exact={true} element={<Actions />} />
					<Route path="/approvals" exact={true} element={<Approvals />} />
					<Route path="/connected" exact={true} element={<Connected />} />
					<Route path="/*" exact={true} element={<Landing />} />
				</Routes>
			</BrowserRouter>
		);
	}
}

export default Index;
