import React, {useEffect, useState} from 'react';
import {get} from '../../Model/Network/Config/Axios'
import {useLocation} from 'react-router-dom'
import Invoice from './Invoice'
import Pr from './Pr'
import Po from './Po';
import GRN from './GRN';
import Rfq from './Rfq';
import {Spinner} from '../Common/fLoader';
import Ques from './question.png'
import Contract from './Contract';
import AmendContract from './ContractAmendment';

function Index(props) {

    let LOCATION = useLocation()
    const PARAMS = new URLSearchParams(LOCATION.search);
    const Token = PARAMS.get('token');

    const [Type, setType] = useState(undefined);
    const [Forms, setForms] = useState([]);
    const [Po_, setPo_] = useState({});
    const [GRN_, setGRN_] = useState({});
    const [contract,setContract] = useState({});
    const [amendContract,setAmendContract] = useState({});
    const [Inputs, setInputs] = useState([]);
    const [Timeline, setTimeline] = useState([]);
    const [primaryColor,setPrimaryColor] = useState(undefined)
    const [logo,setLogo] = useState(undefined)

    useEffect(() => {
        fetchToken(Token)
    }, [Token]);

    useEffect(()=>{
        get(`https://heimdall.eka.io/website/company.details`,(e,r)=>{
            if(r){
                setPrimaryColor(r.color);
                if(r.whiteLabel && r.whiteLabel.sidebar && r.whiteLabel.sidebar.logo) {
                    setLogo(r.whiteLabel.sidebar.logo)
                }
            }
        },{token:Token})
    },[Token])

    const fetchToken = (token) => {
        get('https://heimdall.eka.io/website/task.fetch',(e,r)=>{
            if(r){
                setType(r.details.type)
                setForms(r.forms);
                if(r.inputs){
                    setInputs(r.inputs);
                }
                if(r.timelines){
                    setTimeline(r.timelines);
                }
                if(r.details.type==='po'){
                    setPo_(r)
                }
                if(r.details.type==='grn'){
                    setGRN_(r)
                }
                if(r.details.type==='vendor-contract'){
                    setContract(r)
                }
                if(r.details.type==='contract-amendment'){
                    setAmendContract(r)
                }
            }else{
                setType('invalid')
            }
        },{token})
    }


    return (<>
       {
        Type?
            (Type ==='invoice' || Type === 'utility')
            ?<Invoice logo={logo} type={Type} primaryColor={primaryColor} token={Token} form={Forms} inputs={Inputs} timelines={Timeline}/>
            : Type==='pr'
            ?<Pr logo={logo} primaryColor={primaryColor} token={Token} form={Forms} inputs={Inputs} timelines={Timeline}/>:
            Type === 'rfq' 
            ?<Rfq logo={logo} primaryColor={primaryColor} token={Token} form={Forms} inputs={Inputs} timelines={Timeline}/>
            : Type==='po'
            ?<Po logo={logo} primaryColor={primaryColor} token={Token} form={Forms} po={Po_} inputs={Inputs} timelines={Timeline}/>
            : Type==='grn'
            ?<GRN logo={logo} primaryColor={primaryColor} token={Token} form={Forms} grn={GRN_} inputs={Inputs} timelines={Timeline}/>
            : Type==='vendor-contract'?
            <Contract logo={logo} primaryColor={primaryColor} token={Token} form={Forms} contract={contract} inputs={Inputs} timelines={Timeline}/>
            :  Type==='contract-amendment'?
            <AmendContract logo={logo} primaryColor={primaryColor} token={Token} form={Forms} contract={amendContract} inputs={Inputs} timelines={Timeline}/>
            : 
            
            
            Type==='invalid'
            ?<div className="container cards mt-5" style={{fontSize: '14px', borderRadius: '10px', textAlign: 'center'}}>
                    <img className="img-fluid mb-4 m-auto" src={Ques} style={{maxWidth: '80px'}} alt=""/>
                    <h1 className="display-4 fw-bold">Expired Request</h1>
                    <p>This Request is decprecated and not available for review.</p>
                </div>
            :<></>
        :<Spinner style={{marginTop: '150px'}}/>
        }
    </>);
}

export default Index;