import React from "react";

export const Dialog = ({ children, open = false, onClose = () => {}, style = {} }) => {
	if (!open) return null;

	return (
		<div style={{ position: "fixed", zIndex: "2000", left: "0", top: "0", width: "100vw", height: "100vh", overflow: "auto", backgroundColor: "rgba(0,0,0,0.5)", display: "flex", alignItems: "center", justifyContent: "center" }}>
			<div style={{ position: "relative", background: "#fff", borderRadius: "1rem", overflowY: "auto", maxHeight: "90vh", ...style }}>
				{children}
				<div onClick={onClose} style={{ position: "absolute", top: "0.5rem", right: "1rem", cursor: "pointer", fontSize: "1.5rem" }}>
					<svg className="bi bi-x-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
						<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
						<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
					</svg>
				</div>
			</div>
		</div>
	);
};
