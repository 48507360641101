import React from 'react';
import './index.css';
import PDF_ICON from './pdf.png';
import IMG from './img.png'

function PhotoTile(props) {
    let ICON = '';
    let FLAG = false;
    if(props.link){
        ICON = props.link
    }else{
        ICON = IMG;
        FLAG = true;
    }

    if(ICON.substring(ICON.length - 3)==='pdf'){
        ICON = PDF_ICON;
    }
    return (
        <div className={FLAG?'':'Voucher-attachment'} onClick={(e)=>{e.stopPropagation(); if(!FLAG){window.open(props.link, '_blank')}}} style={{minWidth: '70px', maxWidth: '70px', height: '70px', margin: '7px', cursor: 'pointer', borderRadius: '15px', backgroundColor: 'var(--tile-light-color)', backgroundImage: `url('${ICON}')`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}></div>  
    );
}

export default PhotoTile;