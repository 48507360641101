import React from 'react'
import Props from 'prop-types'

class  FloatInput extends React.Component {

	getValue = () => {
		return this.mRef.value.trim();
	}

	render() {
		return (
			<div style={{ ...this.props.style }}>
				<div>{this.props.title}</div>
				<div style={{
					...this.props.containerStyle
				}}>
					<input ref={ref => {
						this.mRef = ref;
						if (this.props.handleRef) {
							this.props.handleRef({
								ref,
								setValue: val => ref.value = val,
								value: () => ref.value,
								getValue: () => ref.value
							})
						}
					}} min="1" autoComplete='off'
						   defaultValue={this.props.default}
						onChange={this.props.onChange}
						disabled={this.props.disabled}
						className="form-control mt-2" style={{maxWidth: '500px'}} type={this.props.type} placeholder={this.props.placeholder} />
				</div>
			</div>
		)
	}

}

class  FloatInputButton extends React.Component {

	state={
		delta: false
	}
	getValue = () => {
		return this.mRef.value.trim();
	}

	updateValue = ()=>{
		if(this.props.onChange){
			this.props.onChange();
		}
		if(this.mRef.value===''){
			this.setState({delta:false});	
		}else{
			this.setState({delta:true});	
		}
	}

	submitValue = ()=>{
		this.props.onSubmit(this.mRef.value)
	}

	render() {
		return (
			<div className="popup__field field" style={{ ...this.props.style }}>
				<div className="field__label" style={{margin: '20px 0px 10px 0px', fontWeight: 'normal'}} >{this.props.title}</div>
				<div className="field__wrap" style={{
					...this.props.containerStyle
				}}>
					<div style={{display: 'flex'}}>
						<input id='FieldInput' ref={ref => {
							this.mRef = ref;
							if (this.props.handleRef) {
								this.props.handleRef({
									ref,
									setValue: val => ref.value = val,
									value: () => ref.value,
									getValue: () => ref.value
								})
							}
						}} min="1" autoComplete='off'
							defaultValue={this.props.default}
							onChange={()=>{this.updateValue()}}
							disabled={this.props.disabled}
							className="field__input small_input" 
							type={this.props.type} 
							placeholder={this.props.placeholder} 
							style={{fontSize: '12px', height: '40px', width: '100%', padding: '10px'}}
							/>

							{
								this.state.delta?
								<div onClick={this.submitValue} className='flex-center' style={{height:'40px', width: '40px', fontSize: '16px', cursor: 'pointer', borderRadius: '0px 10px 10px 0px', color: '#fff', backgroundColor: '#6C5DD3', marginLeft: '-40px'}}>
								<svg class="bi bi-check-lg" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
								<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
								</svg>
								</div>
								:<></>
							}
						</div>
				</div>
			</div>
		)
	}

}

FloatInput.propTypes = {
	title: Props.string.isRequired,
	placeholder: Props.string,
	type: Props.string,
	handleRef: Props.func,
	onChange: Props.func
}

FloatInput.defaultProps = {
	type: "text",
	onChange: e => { },
	disabled: false
}

export default FloatInput

export {
	FloatInput, FloatInputButton
}
