import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { get, post } from "../../../Model/Network/Config/Axios";
import Tick from "../tick.png";
import Decline from "../decline.png";
import Ques from "../question.png";
import { Spinner } from "../../Common/fLoader";
import "./index.css";
import { Dialog } from "../../Common/Dialog";

function Index(props) {
	let LOCATION = useLocation();
	const PARAMS = new URLSearchParams(LOCATION.search);
	const Token = PARAMS.get("token");
	//Decoding Token for origin
	let Origin = "";
	var base64Url = Token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(
		atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join("")
	);
	let json = JSON.parse(jsonPayload);
	if (json.origin) {
		Origin = json.origin;
	}

	const [Modal, setModal] = useState(undefined);
	const [Status, setStatus] = useState(undefined);
	const [Transactions, setTransactions] = useState([]);
	const [Employee, setEmployee] = useState({});
	const [Response, setResponse] = useState({});
	const [Values, setValues] = useState([]);
	const [Processing, setProcessing] = useState(false);

	let Remarks = useRef();

	useEffect(() => {
		fetchDetails(Token);
	}, [Token]);

	const fetchDetails = (token) => {
		if (!token) {
		}
		get(
			Origin ? `${Origin}employee/others/action/voucher` : "https://heimdall.eka.io/employee/others/action/voucher",
			(e, r) => {
				if (r) {
					setTransactions(r.transactions);
					setEmployee(r.employee);
					setResponse((prev) => ({ ...prev, voucherId: r.voucherId, approvalStatus: r.approvalStatus, permissionStatus: r.permissionStatus, details: r.details }));

					setValues(r.values);
					if (r.approvalStatus === "Declined") {
						setStatus("success");
					} else {
						setStatus("success");
					}
				} else {
					setStatus("invalid");
				}
			},
			{ token: token }
		);
	};

	const renderTable = (item, index) => {
		return (
			<>
				<tr key={`row-${index}`}>
					<td>
						#{item.transId}
						{item.file ? (
							<>
								<div onClick={() => window.open(item.file, "_blank")} style={{ cursor: "pointer", display: "flex", alignItems: "center", fontSize: "12px", marginTop: " 10px", color: "#0d6efd" }}>
									View Attachment
									<svg style={{ marginLeft: "10px" }} className="bi bi-box-arrow-up-right" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
										<path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"></path>
										<path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"></path>
									</svg>
								</div>
							</>
						) : (
							<></>
						)}
					</td>
					<td>{item.service}</td>
					<td>{item.amount}</td>
					<td>{item.reimbursable}</td>
				</tr>
			</>
		);
	};

	const rednerValues = (item) => {
		return (
			<div className="mt-2 col-6 col-md-3">
				<div className="p-3 bg-light ms-2 h-100" style={{ borderRadius: "10px" }}>
					<div className="text-muted">{item.key}</div>
					<div className="fw-bold">{item.value}</div>
				</div>
			</div>
		);
	};

	const approve = () => {
		let data = {
			token: Token,
			action: "approve",
			transId: "",
		};
		setProcessing(true);

		post(Origin ? `${Origin}employee/others/action/voucher` : "https://heimdall.eka.io/employee/others/action/voucher", data, (e, r) => {
			if (r) {
				setModal(undefined);
				setStatus("Approved");
				setProcessing(false);
			} else {
				setProcessing(false);
				alert("Problem in Submitting");
			}
		});
	};

	const decline = () => {
		let remarks = Remarks.current.value;

		if (!remarks) {
			alert("Remarks is required");
			return;
		}

		let data = {
			token: Token,
			remarks: remarks,
			action: "decline",
			transId: "",
		};
		setProcessing(true);

		post(Origin ? `${Origin}employee/others/action/voucher` : "https://heimdall.eka.io/employee/others/action/voucher", data, (e, r) => {
			if (r) {
				setModal(undefined);
				setStatus("Declined");
				setProcessing(false);
			} else {
				setProcessing(false);
				alert("Problem in Declining");
			}
		});
	};

	return (
		<>
			<div className="mt-5 mb-5">
				{Status === "success" ? (
					<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px" }}>
						<h6 className="fw-bold text-muted">{Response.details}</h6>
						<h1 className="display-4 fw-bold">Voucher</h1>

						<div className="p-3 mt-4">
							<h1 className="h5 fw-bold">Details</h1>
							<div className="mt-4 row" style={{ marginLeft: "-1rem" }}>
								<div className="col-md-12" style={{ display: "flex", flexWrap: "wrap" }}>
									<div className="mt-2 col-6 col-md-3">
										<div className="p-3 bg-primary ms-2 h-100" style={{ borderRadius: "10px" }}>
											<div className="text-white">Status</div>
											<div className="text-white fw-bold">{Employee.code}</div>
										</div>
									</div>
									<div className="mt-2 col-6 col-md-3">
										<div className="p-3 bg-light ms-2 h-100" style={{ borderRadius: "10px" }}>
											<div className="text-muted">Name</div>
											<div className="fw-bold">{Employee.name}</div>
										</div>
									</div>

									<div className="mt-2 col-6 col-md-3">
										<div className="p-3 bg-light ms-2 h-100" style={{ borderRadius: "10px" }}>
											<div className="text-muted">Code</div>
											<div className="fw-bold">{Employee.code}</div>
										</div>
									</div>
									{Values.map(rednerValues)}
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<div className="mt-5">
										<h1 className="h5 fw-bold">Transactions</h1>

										<div className="mt-4 table-responsive">
											<table className="table">
												<thead style={{ lineHeight: "32px" }}>
													<tr style={{ textTransform: "uppercase" }}>
														<th>Details</th>
														<th>Heads</th>
														<th>Amount</th>
														<th>Settled</th>
													</tr>
												</thead>
												<tbody className="table-striped">{Transactions.map(renderTable)}</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							{Response.permissionStatus === "Completed" || Response.approvalStatus === "Approved" || Response.approvalStatus === "Declined" ? (
								<></>
							) : (
								<div className="row" style={{ maxWidth: "800px" }}>
									<div className="mt-3 col-md-4">
										<button onClick={() => setModal("Approve")} className="btn btn-success" type="button" style={{ width: "100%", height: "50px" }}>
											<svg className="bi bi-check-lg me-2" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
												<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
											</svg>
											Approve
										</button>
									</div>
									<div className="mt-3 col-md-4">
										<button onClick={() => setModal("Decline")} className="btn btn-secondary" type="button" style={{ width: "100%", height: "50px" }}>
											Decline
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
				) : Status === "invalid" ? (
					<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px", textAlign: "center" }}>
						<img className="m-auto mb-4 img-fluid" src={Ques} style={{ maxWidth: "80px" }} alt="" />
						<h1 className="display-4 fw-bold">Invalid Request</h1>
						<p>This Approval request is decprecated and not available for review.</p>
					</div>
				) : Status === "Approved" ? (
					<>
						<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px", textAlign: "center" }}>
							<img className="m-auto mb-4 img-fluid" src={Tick} style={{ maxWidth: "80px" }} alt="" />
							<h1 className="display-6 fw-bold">Approved</h1>
							<p>This Voucher is now Approved</p>
						</div>
					</>
				) : Status === "Declined" ? (
					<>
						<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px", textAlign: "center" }}>
							<img className="m-auto mb-4 img-fluid" src={Decline} style={{ maxWidth: "80px" }} alt="" />
							<h1 className="display-6 fw-bold">Declined</h1>
							<p>This Voucher is now Declined</p>
						</div>
					</>
				) : (
					<Spinner style={{ marginTop: "150px" }} />
				)}
			</div>
			<Dialog open={Modal} onClose={() => setModal(undefined)} style={{ maxWidth: "500px" }}>
				<div className="row">
					<div className="p-2 col-md-12 position-realtive">
						<div onClick={() => setModal(undefined)} className="position-absolute" style={{ fontSize: "2rem", zIndex: "111", right: "20px", top: "10px", cursor: "pointer" }}>
							<svg className="bi bi-x-circle" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
								<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
								<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
							</svg>
						</div>
						<div>
							{!Processing ? (
								<div className="p-5 m-auto text-center" data-aos="fade-up">
									{Modal === "Approve" ? (
										<>
											<h1 className="display-6 fw-bold">Approve</h1>
											<p>Do you want to approve this Voucher?</p>
										</>
									) : Modal === "Decline" ? (
										<>
											<h1 className="display-6 fw-bold">Decline</h1>
											<p>Are you sure you want to decline this Voucher?</p>
											<input ref={Remarks} style={{ height: "50px" }} className="mt-2 mb-3 form-control" type="text" placeholder="Enter Remarks" />
										</>
									) : (
										<></>
									)}
									{Modal === "Approve" ? (
										<button onClick={approve} className="mt-3 btn btn-success" type="button" style={{ height: "50px" }}>
											<svg className="bi bi-check-lg me-2" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
												<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
											</svg>
											Submit
										</button>
									) : Modal === "Decline" ? (
										<button onClick={decline} className="mt-3 btn btn-danger" type="button" style={{ height: "50px" }}>
											Decline
										</button>
									) : (
										<></>
									)}
								</div>
							) : (
								<div className="p-5 m-auto text-center" data-aos="fade-up">
									<Spinner />
								</div>
							)}
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
}

export default Index;
