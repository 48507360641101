import React, { useEffect, useRef, useState } from "react";
import { get, post } from "../../../Model/Network/Config/Axios";
import Tick from "../tick.png";
import Decline from "../decline.png";
import Ques from "../question.png";
import { Spinner } from "../../Common/fLoader";
import { CustomForm as Form } from "../../Common/Form";
import TabSwtich from "../../Common/fTabSwitch";
import Track from "../../Common/fTrack";
import moment from "moment";
import { Dialog } from "../../Common/Dialog";

function Index(props) {
	const Token = props.token;
	//Decoding Token for origin
	let Origin = "";
	var base64Url = Token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(
		atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join("")
	);
	let json = JSON.parse(jsonPayload);
	if (json.origin) {
		Origin = json.origin;
	}

	const [Response, setResponse] = useState([]);
	const [PrId, setPrId] = useState("");
	const [Category, setCategory] = useState("");
	const [Modal, setModal] = useState(undefined);
	const [Item, setItem] = useState(undefined);

	const [Status, setStatus] = useState(undefined);
	const [Processing, setProcessing] = useState(false);

	const [getTab, setTab] = useState(1);

	let Remarks = useRef(),
		Qty = useRef();
	const FORM = useRef(null);

	let TAX = 0,
		BASE = 0;

	let tabs = [
		{
			name: "RFQ Request",
			active: getTab === 1,
			onClick: () => {
				setTab(1);
			},
		},
		{
			name: "Inputs",
			active: getTab === 2,
			onClick: () => {
				setTab(2);
			},
		},
		{
			name: "Timeline",
			active: getTab === 3,
			onClick: () => {
				setTab(3);
			},
		},
	];

	useEffect(() => {
		fetchDetails(Token);
	}, [Token]);

	const fetchDetails = (token) => {
		if (!token) {
		}
		get(
			Origin ? `${Origin}/website/task.quote` : "https://heimdall.eka.io/website/task.quote",
			(e, r) => {
				if (r) {
					setResponse(r.items ? r.items : []);
					setPrId(r.purchaseId ? r.purchaseId : []);
					setCategory(r.category ? r.category : []);

					setStatus("Success");
				} else {
					setStatus("Invalid");
				}
			},
			{ token }
		);
	};

	const removeItem = () => {
		let remarks = Remarks.current.value;

		if (!remarks) {
			alert("Remarks is required");
			return;
		}

		let data = {
			token: Token,
			id: Item,
			remarks: remarks,
		};

		setProcessing(true);
		post(`${Origin}task.remove`, data, (e, r) => {
			if (r) {
				setModal(undefined);
				setProcessing(false);
				setItem(undefined);
				fetchDetails(Token);
			} else {
				setProcessing(false);
				setItem(undefined);
				alert("Problem in removing item");
			}
		});
	};

	const updateItem = () => {
		let remarks = Remarks.current.value;
		let qty = Qty.current.value;

		if (!qty) {
			alert("Quanity is required");
			return;
		}
		if (!remarks) {
			alert("Remarks is required");
			return;
		}

		let data = {
			token: Token,
			id: Item,
			qtty: parseInt(qty),
			remarks: remarks,
		};

		setProcessing(true);
		post(`${Origin}/website/task.update`, data, (e, r) => {
			if (r) {
				setModal(undefined);
				setProcessing(false);
				setItem(undefined);
				fetchDetails(Token);
			} else {
				setProcessing(false);
				setItem(undefined);
				alert("Problem in updating item");
			}
		});
	};

	const renderTable = (item, index) => {
		let base = parseFloat(item.vendorPrice) * parseFloat(item.qtty);
		let tax = (base / 100) * parseFloat(item.taxPct);

		TAX += tax;
		BASE += base;

		return (
			<tr key={`row-${index}`}>
				<td>
					{item.name}
					<br />
					Address: {item.address} <br />
					{item.specs ? (
						<>
							File:{" "}
							<span
								onClick={() => {
									window.open(item.specs, "_blank");
								}}
								style={{
									fontWeight: "600",
									color: "#0d6efd",
									cursor: "pointer",
								}}
							>
								View
							</span>
						</>
					) : null}
				</td>
				<td>{item.submission ? item.submission : "-"}</td>
				<td>
					{item.qtty} {item.unit}
				</td>
				<td>{item.vendorPrice}</td>
				<td>{tax.toFixed(2)}</td>
				<td>{base.toFixed(2)}</td>
				<td>{tax + base}</td>
			</tr>
		);
	};

	const approve = () => {
		FORM.current.getForm((cb) => {
			let data = {
				token: Token,
				forms: cb,
			};

			setProcessing(true);
			post(`${Origin}/website/task.post`, data, (e, r) => {
				if (r) {
					setModal(undefined);
					setStatus("Approved");
					setProcessing(false);
				} else {
					setProcessing(false);
					alert("Problem in Approving");
				}
			});
		});
	};

	const decline = () => {
		let remarks = Remarks.current.value;

		if (!remarks) {
			alert("Remarks is required");
			return;
		}

		let data = {
			token: Token,
			remarks: remarks,
		};

		setProcessing(true);
		post(`${Origin}/website/task.cancel`, data, (e, r) => {
			if (r) {
				setModal(undefined);
				setStatus("Declined");
				setProcessing(false);
			} else {
				setProcessing(false);
				alert("Problem in Declining");
			}
		});
	};

	return (
		<>
			<div className="mt-5 mb-5">
				{Status === "Success" ? (
					<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px" }}>
						<h6 className="fw-bold text-muted">
							{Category} / {PrId}
						</h6>
						<div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
							<div>
								<h1 className="display-4 fw-bold">RFQ Request</h1>
								<p>You have pending approval for this RFQ request. Please verify the details and approve</p>
							</div>
							{props.logo ? <img alt="" src={props.logo} style={{ height: 40 }} /> : null}
						</div>

						<TabSwtich primaryColor={props.primaryColor} tabs={tabs} />

						{getTab === 1 ? (
							<>
								<div className="mt-5 row">
									<div className="col-md-12">
										<div className="table-responsive">
											<table className="table table-bordered">
												<thead style={{ lineHeight: "32px" }}>
													<tr style={{ textTransform: "uppercase" }}>
														<th>Details</th>
														<th>Submissions</th>
														<th>Qty</th>
														<th>Price</th>
														<th>Tax</th>
														<th>Base</th>
														<th>Total</th>
													</tr>
												</thead>
												<tbody className="table-striped">{Response.map(renderTable)}</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="mt-3 row" style={{ maxWidth: 500 }}>
									<div className="col-md-12">
										<span className="fw-bold">TAXES</span>
										<div className="mt-2 d-flex">
											<div className="me-4 text-muted">
												<div>Base Amount:</div>
												<div>Tax Amount:</div>
												<div>Total Amount:</div>
											</div>
											<div className="fw-bold">
												<div>{BASE.toFixed(2)}</div>
												<div>{TAX.toFixed(2)}</div>
												<div>{parseFloat(BASE.toFixed(2)) + parseFloat(TAX.toFixed(2))}</div>
											</div>
										</div>
									</div>
								</div>
								<div className="p-3 mt-4 row">
									<div className="p-4 col-md-12">
										{props.form.length ? <span className="fw-bold">Custom Form</span> : <></>}
										<Form token={Token} title="" des="" forms={props.form} handleRef={(ref) => (FORM.current = ref)} />
									</div>
								</div>
								<div className="mt-4 row" style={{ maxWidth: "500px" }}>
									<div className="mt-3 col-md-6">
										<button onClick={() => setModal("Approve")} className="btn btn-success" type="button" style={{ width: "100%", height: "50px" }}>
											<svg className="bi bi-check-lg me-2" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
												<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
											</svg>
											Approve
										</button>
									</div>
									<div className="mt-3 col-md-6">
										<button onClick={() => setModal("Decline")} className="btn btn-secondary" type="button" style={{ width: "100%", height: "50px" }}>
											Decline
										</button>
									</div>
								</div>
							</>
						) : getTab === 2 ? (
							<>
								<div className="p-3 mt-3 row">
									<div className="p-4 col-md-12">
										<span className="fw-bold">Inputs</span>
										<div className="mt-4">
											{props.inputs ? (
												props.inputs.map((item) => {
													let value = item.value ? item.value : "NA";
													let Links = undefined;
													if (value[0] === "[") {
														value = JSON.parse(value);
														Links = value.map((link, index) => {
															return (
																<span onClick={() => window.open(link, "_blank")} style={{ color: "#6c5dd3", cursor: "pointer", fontWeight: "bold" }}>
																	File {index + 1}&nbsp;&nbsp;&nbsp;
																</span>
															);
														});
													}

													return (
														<div style={{ display: "flex", marginTop: "10px", justifyContent: "space-between", maxWidth: "400px", border: "1px solid #ddd", padding: "10px", borderRadius: "7px", background: "#f2f2f2" }}>
															<div style={{ fontWeight: "bold" }}>{item.key}</div>
															<div>{Links ? Links : value}</div>
														</div>
													);
												})
											) : (
												<></>
											)}
										</div>
									</div>
								</div>
							</>
						) : getTab === 3 ? (
							<>
								<div className="p-4 mt-5" style={{ border: "1px solid #ddd", borderRadius: "10px" }}>
									{props.timelines ? (
										<Track
											stages={[
												...props.timelines.map((log) => {
													let DESC = (
														<div>
															<b>{log.name}</b>
															<br />
															<div style={{ marginTop: "5px" }}>Type: {log.type}</div>
															{log.status ? <div style={{ marginTop: "5px" }}>Status: {log.status}</div> : null}
															<div style={{ marginTop: "5px" }}>
																Sent on: {moment(log.time).format("hh:mm a DD MMM YY")}
																<br />
																{log.status === "PENDING" ? "-" : log.status === "DECLINED" ? `Declined on: ${moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : log.actionTime ? `Approved on: ${moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : ""}
															</div>
														</div>
													);

													return {
														title: log.email,
														desc: DESC,
														status: log.type === "start" ? true : log.status ? log.status === "COMPLETED" : false,
													};
												}),
											]}
										/>
									) : (
										<></>
									)}
								</div>
							</>
						) : (
							<></>
						)}
					</div>
				) : Status === "Approved" ? (
					<>
						<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px", textAlign: "center" }}>
							<img className="m-auto mb-4 img-fluid" src={Tick} style={{ maxWidth: "80px" }} alt="" />
							<h1 className="display-6 fw-bold">Approved</h1>
							<p>RFQ Request Approved</p>
						</div>
					</>
				) : Status === "Declined" ? (
					<>
						<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px", textAlign: "center" }}>
							<img className="m-auto mb-4 img-fluid" src={Decline} style={{ maxWidth: "80px" }} alt="" />
							<h1 className="display-6 fw-bold">Declined</h1>
							<p>RFQ Request Decline</p>
						</div>
					</>
				) : Status === "Invalid" ? (
					<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px", textAlign: "center" }}>
						<img className="m-auto mb-4 img-fluid" src={Ques} style={{ maxWidth: "80px" }} alt="" />
						<h1 className="display-4 fw-bold">Invalid Request</h1>
						<p>This Pr request is decprecated and not available for review.</p>
					</div>
				) : (
					<Spinner style={{ marginTop: "150px" }} />
				)}
			</div>
			<Dialog open={Modal} onClose={() => setModal(undefined)} style={{ maxWidth: "500px" }}>
				<div className="row">
					<div className="p-2 col-md-12 position-relative">
						<div>
							{!Processing ? (
								<div className="p-5 m-auto text-center" data-aos="fade-up">
									{Modal === "Approve" ? (
										<>
											<h1 className="display-6 fw-bold">Approve</h1>
											<p>Do you want to approve this RFQ Request?</p>
										</>
									) : Modal === "Decline" ? (
										<>
											<h1 className="display-6 fw-bold">Decline</h1>
											<p>Are you sure you want to decline this RFQ Request?</p>
										</>
									) : Modal === "Remove" ? (
										<>
											<h1 className="display-6 fw-bold">Remove Item</h1>
											<p>Are you sure you want to remove this item from request?</p>
										</>
									) : Modal === "Edit" ? (
										<>
											<h1 className="display-6 fw-bold">Edit Item</h1>
											<p>You can update the Quantity for this item</p>
										</>
									) : (
										<></>
									)}

									{Modal === "Decline" ? (
										<input ref={Remarks} style={{ height: "50px" }} className="mt-2 mb-3 form-control" type="text" placeholder="Enter Remarks" />
									) : Modal === "Edit" ? (
										<>
											<input ref={Qty} style={{ height: "50px" }} className="mt-2 mb-3 form-control" type="number" placeholder="New Quantity" />
											<input ref={Remarks} style={{ height: "50px" }} className="mt-2 mb-3 form-control" type="text" placeholder="Enter Remarks" />
										</>
									) : Modal === "Remove" ? (
										<input ref={Remarks} style={{ height: "50px" }} className="mt-2 mb-3 form-control" type="text" placeholder="Enter Remarks" />
									) : (
										<></>
									)}

									{Modal === "Approve" ? (
										<button onClick={approve} className="mt-3 btn btn-success" type="button" style={{ height: "50px" }}>
											<svg className="bi bi-check-lg me-2" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
												<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
											</svg>
											Approve
										</button>
									) : Modal === "Decline" ? (
										<button onClick={decline} className="mt-3 btn btn-danger" type="button" style={{ height: "50px" }}>
											Decline
										</button>
									) : Modal === "Remove" ? (
										<button onClick={removeItem} className="mt-3 btn btn-danger" type="button" style={{ height: "50px" }}>
											Remove
										</button>
									) : Modal === "Edit" ? (
										<button onClick={updateItem} className="mt-3 btn btn-success" type="button" style={{ height: "50px" }}>
											Update
										</button>
									) : (
										<></>
									)}
								</div>
							) : (
								<div className="p-5 m-auto text-center" data-aos="fade-up">
									<Spinner />
								</div>
							)}
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
}

export default Index;
