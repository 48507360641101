import React, { useEffect, useRef, useState } from "react";
import { get, post } from "../../../Model/Network/Config/Axios";
import Tick from "../tick.png";
import Decline from "../decline.png";
import Revert from "../revert.png";
import Ques from "../question.png";
import { Spinner } from "../../Common/fLoader";
import PhotoTile from "../../Common/fPhoto";
import TabSwtich from "../../Common/fTabSwitch";
import Track from "../../Common/fTrack";
import "./index.css";
import { CustomForm as Form } from "../../Common/Form";
import moment from "moment";
import { Dialog } from "../../Common/Dialog";

function Index(props) {
	const Token = props.token;
	//Decoding Token for origin
	let Origin = "";
	var base64Url = Token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(
		atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join("")
	);
	let json = JSON.parse(jsonPayload);
	if (json.origin) {
		Origin = json.origin;
	}

	const [getTab, setTab] = useState(1);

	const [Invoice, setInvoice] = useState({});
	const [Owner, setOwner] = useState({});
	const [Vendor, setVendor] = useState({});
	const [TaxInfo, setTaxInfo] = useState({});
	const [Timeline, setTimeline] = useState([]);
	const [Chips, setChips] = useState([]);
	const [Billing, setBilling] = useState({});
	const [DeliverTo, setDeliverTo] = useState({});
	const [Category, setCategory] = useState("");
	const [details, setDetails] = useState({});

	const [Modal, setModal] = useState(undefined);

	const [Status, setStatus] = useState(undefined);
	const [Processing, setProcessing] = useState(false);

	const [Items, setItems] = useState([]);

	const [Tags, setTags] = useState({});

	const FORM = useRef(null);

	let Remarks = useRef();

	let tabs = [
		{
			name: "Invoice",
			active: getTab === 1,
			onClick: () => {
				setTab(1);
			},
		},
		{
			name: "Basic Details",
			active: getTab === 2,
			onClick: () => {
				setTab(2);
			},
		},
		{
			name: "Custom Fields",
			active: getTab === 3,
			onClick: () => {
				setTab(3);
			},
		},
		{
			name: "Inputs",
			active: getTab === 4,
			onClick: () => {
				setTab(4);
			},
		},
		{
			name: "Audit Trail",
			active: getTab === 5,
			onClick: () => {
				setTab(5);
			},
		},
	];

	useEffect(() => {
		fetchDetails(Token);
		// eslint-disable-next-line
	}, [Token]);

	const fetchDetails = (token) => {
		get(
			Origin ? `${Origin}/website/task.invoice` : "/website/task.invoice",
			(e, r) => {
				if (r) {
					setInvoice(r.invoice);
					setOwner(r.owner);
					setVendor(r.vendor);
					setTaxInfo(r.taxInformation);
					setChips(r.chips);
					setBilling(r.address);
					setDeliverTo(r.deliverTo);
					setCategory(r.category);
					setTags(r.tags);
					setTimeline(r.timelines);
					setItems((r.content && typeof r.content === 'string')?  JSON.parse(r.content).items : (r.content?.items||[]));
					setDetails((r.content && typeof r.content === 'string') ? JSON.parse(r.content) : r.content);
					setStatus("success");
				} else {
					setStatus("invalid");
				}
			},
			{ token }
		);
	};


	const renderTable = (item, index) => {
		return (
			<tr key={`row-${index}`}>
				<td>
					{item.name}
					<br />
					Notes : {item.notes || "NA"} <br />
					HSN : {item.hsn || "NA"} <br />
					Service : {item.service}
					<br />
					{item.data && item.data.length > 0
						? item.data.map((i, index1) => {
								return (
									<div key={index1}>
										{i.key} : {i.value}
									</div>
								);
						  })
						: null}
				</td>
				<td>
					{TaxInfo.currency} {item.base ? item.base.toFixed(2) : item.base}
				</td>
				<td>{item.qtty ? item.qtty.toFixed(2) : item.qtty}</td>
				<td>
					{TaxInfo.currency} {item.tax ? item.tax.toFixed(2) : item.tax}
				</td>
				<td>{item.taxSlab ? item.taxSlab.toFixed(2) : item.taxSlab}</td>
				<td>
					{TaxInfo.currency} {item.amount ? item.amount.toFixed(2) : item.amount}
				</td>
			</tr>
		);
	};

	const renderOwner = (item, index) => {
		return (
			<tr key={index}>
				<th>{item.key}</th>
				<td>{item.value}</td>
			</tr>
		);
	};

	const approve = () => {
		FORM.current.getForm((cb) => {
			let data = {
				token: Token,
				forms: cb,
			};

			setProcessing(true);
			post(`${Origin}website/task.post`, data, (e, r) => {
				if (r) {
					setModal(undefined);
					setStatus("Approved");
					setProcessing(false);
				} else {
					setProcessing(false);
					alert("Problem in Approving");
				}
			});
		});
	};

	const decline = () => {
		let remarks = Remarks.current.value;

		if (!remarks) {
			alert("Remarks is required");
			return;
		}

		let data = {
			token: Token,
			remarks: remarks,
		};

		setProcessing(true);
		post(`${Origin}/website/task.cancel`, data, (e, r) => {
			if (r) {
				setModal(undefined);
				setStatus("Declined");
				setProcessing(false);
			} else {
				setProcessing(false);
				alert("Problem in Declining");
			}
		});
	};

	const revert = () => {
		let remarks = Remarks.current.value;

		if (!remarks) {
			alert("Remarks is required");
			return;
		}

		let data = {
			token: Token,
			remarks: remarks,
		};

		setProcessing(true);
		post(`${Origin}/website/task.back`, data, (e, r) => {
			if (r) {
				setModal(undefined);
				setStatus("Reverted");
				setProcessing(false);
			} else {
				setProcessing(false);
				alert("Problem in Reverting");
			}
		});
	};

	return (
		<>
			<div className="mt-5 mb-5">
				{Status === "success" ? (
					<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px" }}>
						<h6 className="fw-bold text-muted">
							{Category} / {Invoice.counterId} / {Invoice.invoiceId}
						</h6>
						<div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
							<div>
								<h1 className="display-4 fw-bold">{props.type === 'utility' ? 'Utility Approval' :'Invoice'}</h1>
								<p className="mb-5">
									{props.type === 'utility' ? 'Utility bill of': 'Invoice of'} {TaxInfo.currency} {Invoice.amount ? Invoice.amount.toFixed(2) : Invoice.amount} submitted by {Vendor.name}
								</p>
							</div>
							{props.logo ? <img alt="" src={props.logo} style={{ height: 40 }} /> : null}
						</div>

						<TabSwtich primaryColor={props.primaryColor} tabs={tabs} />

						{getTab === 1 ? (
							<div>
								<div className="mt-5 row">
									<div className="col-md-12">
										<div>
											{Chips.map((chip, index) => {
												return (
													<div key={index} className="status-badge" style={{ background: "#f2f2f2", marginLeft: "5px" }}>
														{chip.key}: {chip.value}
													</div>
												);
											})}
										</div>
										<div className="p-4 mt-4" style={{ border: "1px solid #ddd", borderRadius: "10px" }}>
											<div className="row">
												<div className="col-md-6">
													<b>Invoice ID : {Invoice.invoiceId}</b>
													<br />
													<div
														style={{
															display: "flex",
															fontWeight: "bold",
														}}
													>
														PO Number :
														{details && details.orderIds && details.orderIds.length > 0
															? details.orderIds.map((i, index) => {
																	return <div key={index}>{i}</div>;
															  })
															: null}
													</div>
													Date: {Invoice.date}
												</div>
												<div className="col-md-6">
													<b>Vendor:</b>
													<br />
													{Vendor.name}
													<br />
													{Vendor.gstin}
													<br />
													{Vendor.email}
													<br />
													{Vendor.mobile}
													<br />
												</div>
											</div>

											<div className="pb-4 row">
												<div className="col-md-6">
													<b>Billing Address:</b>
													<br />
													{Billing.address} {Billing.city}, {Billing.title}
												</div>
												<div className="col-md-6">
													<b>Delivery Address:</b>
													<br />
													{DeliverTo.address} {DeliverTo.city}, {DeliverTo.title}
												</div>
											</div>

											<div className="table-responsive">
												<table className="table table-bordered">
													<thead style={{ lineHeight: "32px" }}>
														<tr style={{ textTransform: "uppercase" }}>
															<th>Description</th>
															<th>Base Amount</th>
															<th>Qtty</th>
															<th>Tax Amount</th>
															<th>Gst %</th>
															<th>Amount</th>
														</tr>
													</thead>
													<tbody className="table-striped">{Items.map(renderTable)}</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div className="mt-3 row" style={{ justifyContent: "end" }}>
									<div className="col-md-12" style={{ display: "inline-flex", justifyContent: "end" }}>
										{TaxInfo.currency === "INR" ? (
											<div className="mt-2 d-flex" style={{ border: "1px solid #ddd", borderRadius: "10px" }}>
												<div className="me-4 text-muted">
													<div className="p-2 ps-3" style={{ borderBottom: "1px solid #ddd" }}>
														CGST:
													</div>
													<div className="p-2 ps-3" style={{ borderBottom: "1px solid #ddd" }}>
														SGST:
													</div>
													<div className="p-2 ps-3" style={{ borderBottom: "1px solid #ddd" }}>
														IGST:
													</div>
													<div className="p-2 ps-3 fw-bold" style={{ borderBottom: "1px solid #ddd", whiteSpace: "nowrap" }}>
														Base Amount:
													</div>
													<div className="p-2 ps-3" style={{ borderBottom: "1px solid #ddd", whiteSpace: "nowrap" }}>
														Tax Amount:
													</div>
													<div className="p-2 ps-3" style={{ borderBottom: "1px solid #ddd", whiteSpace: "nowrap" }}>
														Total Amount:
													</div>
													<div className="p-2 ps-3" style={{ borderBottom: "1px solid #ddd", whiteSpace: "nowrap" }}>
														TDS Amount:
													</div>
													<div className="p-2 ps-3 fw-bold">Payable Amount:</div>
												</div>
												<div className="fw-bold">
													<div className="p-2 ps-4 pe-4" style={{ borderBottom: "1px solid #ddd", marginLeft: "-30px" }}>
														{TaxInfo.cgst ? TaxInfo.cgst.toFixed(2) : TaxInfo.cgst}
													</div>
													<div className="p-2 ps-4 pe-4" style={{ borderBottom: "1px solid #ddd", marginLeft: "-30px" }}>
														{TaxInfo.sgst ? TaxInfo.sgst.toFixed(2) : TaxInfo.sgst}
													</div>
													<div className="p-2 ps-4 pe-4" style={{ borderBottom: "1px solid #ddd", marginLeft: "-30px" }}>
														{TaxInfo.igst ? TaxInfo.igst.toFixed(2) : TaxInfo.igst}
													</div>
													<div className="p-2 ps-4 pe-4" style={{ borderBottom: "1px solid #ddd", marginLeft: "-30px" }}>
														{TaxInfo.baseAmount ? TaxInfo.baseAmount.toFixed(2) : TaxInfo.baseAmount}
													</div>
													<div className="p-2 ps-4 pe-4" style={{ borderBottom: "1px solid #ddd", marginLeft: "-30px" }}>
														{TaxInfo.gst ? TaxInfo.gst.toFixed(2) : TaxInfo.gst}
													</div>
													<div className="p-2 ps-4 pe-4" style={{ borderBottom: "1px solid #ddd", marginLeft: "-30px" }}>
														{TaxInfo.total ? TaxInfo.total.toFixed(2) : TaxInfo.total}
													</div>
													<div className="p-2 ps-4 pe-4" style={{ borderBottom: "1px solid #ddd", marginLeft: "-30px" }}>
														{TaxInfo.tds ? TaxInfo.tds.toFixed(2) : TaxInfo.tds}
													</div>
													<div className="p-2 ps-4 pe-4" style={{ marginLeft: "-30px", whiteSpace: "nowrap" }}>
														{TaxInfo.paidAmount ? TaxInfo.paidAmount.toFixed(2) : TaxInfo.paidAmount}
													</div>
												</div>
											</div>
										) : (
											<div className="mt-2 d-flex" style={{ border: "1px solid #ddd", borderRadius: "10px" }}>
												<div className="me-4 text-muted">
													<div className="p-2 ps-3 fw-bold">Payable Amount:</div>
												</div>
												<div className="fw-bold">
													<div className="p-2 ps-4 pe-4" style={{ marginLeft: "-30px", whiteSpace: "nowrap" }}>
														{TaxInfo.amount}
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="p-3 mt-3 row">
									<div className="p-4 col-md-12" style={{ border: "1px solid #ddd", borderRadius: "10px" }}>
										<span className="fw-bold">Attachments</span>
										<div className="mt-4 d-flex" style={{ flexWrap: "wrap" }}>
											{Invoice.files.length > 0 ? (
												Invoice.files.map((item, index) => {
													return <PhotoTile key={`Photo-${index}`} link={item} />;
												})
											) : (
												<></>
											)}
										</div>
									</div>
								</div>
								<div className="p-3 row">
									<div className="p-4 col-md-12" style={{ border: "1px solid #ddd", borderRadius: "10px" }}>
										<span className="fw-bold">Custom Form</span>
										<Form token={Token} title="" des="" forms={props.form} handleRef={(ref) => (FORM.current = ref)} />
									</div>
								</div>
								<div className="row" style={{ maxWidth: "800px" }}>
									<div className="mt-3 col-md-4">
										<button onClick={() => setModal("Approve")} className="btn btn-success" type="button" style={{ width: "100%", height: "50px" }}>
											<svg className="bi bi-check-lg me-2" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
												<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
											</svg>
											Submit
										</button>
									</div>
									<div className="mt-3 col-md-4">
										<button onClick={() => setModal("Decline")} className="btn btn-secondary" type="button" style={{ width: "100%", height: "50px" }}>
											Decline
										</button>
									</div>
								</div>
							</div>
						) : getTab === 2 ? (
							<div>
								<div className="mt-5 table-responsive">
									<table className="table mt-3 table-bordered">
										<tbody className="table-striped">
											<tr style={{ background: "#333" }}>
												<td colSpan="2">
													<span className="text-white">Vendor Information</span>
												</td>
											</tr>
											<tr>
												<th>Vendor Name</th>
												<td>{Vendor.name}</td>
											</tr>
											<tr>
												<th>Vendor Email</th>
												<td>{Vendor.email}</td>
											</tr>
											<tr>
												<th>Vendor Mobile</th>
												<td>{Vendor.mobile}</td>
											</tr>
											<tr>
												<th>Vendor Mobile</th>
												<td>{Vendor.gstin ? Vendor.gstin : "NA"}</td>
											</tr>
											<tr>
												<th>Vendor Bank</th>
												<td>{Vendor.bank ? Vendor.bank.name : "Not Available"}</td>
											</tr>
											<tr style={{ background: "#333" }}>
												<td colSpan="2">
													<span className="text-white">Address Information</span>
												</td>
											</tr>
											<tr>
												<th>Address</th>
												<td>{Billing.address}</td>
											</tr>

											<tr>
												<th>Title</th>
												<td>{Billing.title}</td>
											</tr>
											<tr>
												<th>GSTIN</th>
												<td>{Billing.gstin}</td>
											</tr>
											<tr style={{ background: "#333" }}>
												<td colSpan="2">
													<span className="text-white">Owner Information</span>
												</td>
											</tr>
											{Owner.values.map(renderOwner)}
										</tbody>
									</table>
								</div>
							</div>
						) : getTab === 3 ? (
							<div className="p-3 mt-3 row">
								<div className="p-4 col-md-12">
									<span className="fw-bold">Custom Fields</span>
									<div className="mt-4">
										{Object.keys(Tags).map((item, index) => {
											let value = Tags[item];

											if (value && value.includes("https")) {
												return (
													<div key={index} style={{ display: "flex", marginTop: "10px", justifyContent: "space-between", maxWidth: "400px", border: "1px solid #ddd", padding: "10px", borderRadius: "7px", background: "#f2f2f2" }}>
														<div style={{ fontWeight: "bold" }}>{item}</div>

														<div
															onClick={() => {
																window.open(value, "_blank");
															}}
															style={{
																cursor: "pointer",
																color: props.primaryColor,
															}}
														>
															{" "}
															View Link
														</div>
													</div>
												);
											}

											return (
												<div key={index} style={{ display: "flex", marginTop: "10px", justifyContent: "space-between", maxWidth: "400px", border: "1px solid #ddd", padding: "10px", borderRadius: "7px", background: "#f2f2f2" }}>
													<div style={{ fontWeight: "bold" }}>{item}</div>

													<div>{Tags[item]}</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						) : getTab === 4 ? (
							<div className="p-3 mt-3 row">
								<div className="p-4 col-md-12">
									<span className="fw-bold">Inputs</span>
									<div className="mt-4">
										{props.inputs ? (
											props.inputs.map((item, index) => {
												if (item.value && item.value.includes("https")) {
													return (
														<div key={index} style={{ display: "flex", marginTop: "10px", justifyContent: "space-between", maxWidth: "400px", border: "1px solid #ddd", padding: "10px", borderRadius: "7px", background: "#f2f2f2" }}>
															<div style={{ fontWeight: "bold" }}>{item.key}</div>

															<div
																onClick={() => {
																	window.open(item.value, "_blank");
																}}
																style={{
																	cursor: "pointer",
																	color: props.primaryColor,
																}}
															>
																{" "}
																View Link
															</div>
														</div>
													);
												}
												return (
													<div style={{ display: "flex", marginTop: "10px", justifyContent: "space-between", maxWidth: "400px", border: "1px solid #ddd", padding: "10px", borderRadius: "7px", background: "#f2f2f2" }}>
														<div style={{ fontWeight: "bold" }}>{item.key}</div>
														<div>{item.value}</div>
													</div>
												);
											})
										) : (
											<></>
										)}
									</div>
								</div>
							</div>
						) : getTab === 5 ? (
							<div className="p-4 mt-5" style={{ border: "1px solid #ddd", borderRadius: "10px" }}>
								{props.timelines ? (
									<Track
										stages={[
											...Timeline.map((log) => {
												let DESC = (
													<div>
														<b>{log.name}</b>
														<br />
														<div style={{ marginTop: "5px" }}>Type: {log.type}</div>
														{log.status ? <div style={{ marginTop: "5px" }}>Status: {log.status}</div> : null}
														<div style={{ marginTop: "5px" }}>
															Sent on: {moment(log.time).format("hh:mm a DD MMM YY")}
															<br />
															{log.status === "PENDING" ? "-" : log.status === "DECLINED" ? `Declined on: ${moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : log.actionTime ? `Approved on: ${moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : ""}
														</div>
													</div>
												);

												return {
													title: log.email,
													desc: DESC,
													status: log.type === "start" ? true : log.status ? log.status === "COMPLETED" : false,
												};
											}),
										]}
									/>
								) : (
									<></>
								)}
							</div>
						) : (
							<></>
						)}
					</div>
				) : Status === "Approved" ? (
					<>
						<img className="m-auto mb-4 img-fluid" src={Tick} style={{ maxWidth: "80px" }} alt="" />
						<h1 className="display-6 fw-bold">Approved</h1>
						<p>This Invoice is now Approved</p>
					</>
				) : Status === "Declined" ? (
					<>
						<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px", textAlign: "center" }}>
							<img className="m-auto mb-4 img-fluid" src={Decline} style={{ maxWidth: "80px" }} alt="" />
							<h1 className="display-6 fw-bold">Declined</h1>
							<p>This Invoice is now Declined</p>
						</div>
					</>
				) : Status === "Reverted" ? (
					<>
						<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px", textAlign: "center" }}>
							<img className="m-auto mb-4 img-fluid" src={Revert} style={{ maxWidth: "80px" }} alt="" />
							<h1 className="display-6 fw-bold">Reverted</h1>
							<p>This Invoice is now Reverted</p>
						</div>
					</>
				) : Status === "invalid" ? (
					<div className="container cards" style={{ fontSize: "14px", borderRadius: "10px", textAlign: "center" }}>
						<img className="m-auto mb-4 img-fluid" src={Ques} style={{ maxWidth: "80px" }} alt="" />
						<h1 className="display-4 fw-bold">Invalid Request</h1>
						<p>This Invoice request is decprecated and not available for review.</p>
					</div>
				) : (
					<Spinner style={{ marginTop: "150px" }} />
				)}
			</div>
			<Dialog open={Modal} onClose={() => setModal(undefined)}>
				<div className="row">
					<div className="p-2 col-md-12 position-relative">
						<div>
							{!Processing ? (
								<div className="p-5 m-auto text-center" data-aos="fade-up">
									{Modal === "Approve" ? (
										<>
											<h1 className="display-6 fw-bold">Approve</h1>
											<p>Do you want to approve this Invoice?</p>
										</>
									) : Modal === "Decline" ? (
										<>
											<h1 className="display-6 fw-bold">Decline</h1>
											<p>Are you sure you want to decline this Invoice?</p>
										</>
									) : Modal === "Revert" ? (
										<>
											<h1 className="display-6 fw-bold">Revert</h1>
											<p>Are you sure you want to revert this Invoice?</p>
										</>
									) : (
										<></>
									)}

									{Modal === "Decline" ? <input ref={Remarks} style={{ height: "50px" }} className="mt-2 mb-3 form-control" type="text" placeholder="Enter Remarks" /> : <></>}

									{Modal === "Approve" ? (
										<button onClick={approve} className="mt-3 btn btn-success" type="button" style={{ height: "50px" }}>
											<svg className="bi bi-check-lg me-2" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
												<path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
											</svg>
											Approve
										</button>
									) : Modal === "Decline" ? (
										<button onClick={decline} className="mt-3 btn btn-danger" type="button" style={{ height: "50px" }}>
											Decline
										</button>
									) : Modal === "Revert" ? (
										<button onClick={revert} className="mt-3 btn btn-danger" type="button" style={{ height: "50px" }}>
											Revert
										</button>
									) : (
										<></>
									)}
								</div>
							) : (
								<div className="p-5 m-auto text-center" data-aos="fade-up">
									<Spinner />
								</div>
							)}
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
}

export default Index;
